function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
}

function validatePassword(password) {
  const re =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
  return !re.test(String(password).toLowerCase());
}
function validatePhoneNumber(number) {
  const result = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
  return !result.test(number);
}
export { validateEmail, validatePassword, validatePhoneNumber };
