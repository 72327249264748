<template>
  <div>
    <div class="pb-4 pt-4">
      <h4 class="grey--text pl-4">
        비밀번호 재설정을 위한 새로운 비밀번호를 입력해주세요.
      </h4>
    </div>
    <div>
      <v-text-field
        v-model="password"
        :append-icon="isPw ? 'mdi-eye' : 'mdi-eye-off'"
        label="신규 비밀번호"
        prepend-icon="mdi-lock"
        :type="isPw ? 'text' : 'password'"
        :hint="passwordHint"
        :persistent-hint="true"
        @click:append="isPw = !isPw"
        style="width: 100%"
        class="pt-4"
      />
      <v-text-field
        v-model="passwordConfirm"
        :append-icon="isPwConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        label="신규 비밀번호 확인"
        prepend-icon="mdi-lock"
        :type="isPwConfirm ? 'text' : 'password'"
        :hint="passwordConfirmHint"
        :persistent-hint="true"
        @click:append="isPwConfirm = !isPwConfirm"
        style="width: 100%"
        class="pt-4"
      />
    </div>
    <div class="pt-6 pb-4">
      <v-btn
        :disabled="isChangePassword"
        color="primary"
        block
        @click="pwChange()"
      >
        비밀번호 변경
      </v-btn>
    </div>
  </div>
</template>

<script>
import { validatePassword } from "@/utils/validation";
import { modifyPassword } from "@/api/user";
export default {
  data() {
    return {
      password: "",
      passwordConfirm: "",
      isPwConfirm: false,
      isPw: false,
    };
  },
  computed: {
    isChangePassword() {
      return !(
        this.password &&
        !validatePassword(this.password) &&
        this.passwordConfirm === this.password
      );
    },
    passwordHint() {
      let hint = "";

      if (!this.password) hint = "신규 비밀번호를 입력하세요.";
      if (this.password && validatePassword(this.password))
        hint = "비밀번호는 8~20자 이상 영문,숫자,특수문자로 입력해주세요";

      return hint;
    },
    passwordConfirmHint() {
      let hint = "";

      if (!this.password) hint = "패스워드를 입력해주세요";
      if (this.passwordConfirm && this.password !== this.passwordConfirm)
        hint = "신규 비밀번호가 일치하지않습니다.";
      if (this.passwordConfirm && this.password === this.passwordConfirm)
        hint = "신규비밀번호와 일치합니다.";

      return hint;
    },
  },
  methods: {
    async pwChange() {
      if (confirm("비밀번호를 변경하시겠습니까?")) {
        try {
          const result = await modifyPassword({
            uuid: this.$store.state.auth_uuid,
            password: this.password,
          });

          if (result.status !== 200) throw `error: ${result.status}`;

          if (result.data) {
            alert("신규 비밀번호로 변경되었습니다. 다시 로그인해주세요");
            this.$store.commit("setLoginForm");
          } else {
            alert("비밀번호 변경시 오류가 발생하였습니다. 다시 시도 해주세요");
            this.initPassword();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    initPassword() {
      this.password = "";
      this.passwordConfirm = "";
    },
  },
};
</script>

<style></style>
