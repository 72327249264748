<template>
  <div class="signupTermsForm">
    <div class="termsForm">
      <div class="d-flex termsTitle">
        <span>서비스 이용약관</span>
      </div>
      <div class="d-flex justify-center">
        <div class="terms">
          <p><strong>제 1 조 (목적)</strong></p>
          <p>
            본 약관은 주식회사 소프트마니(이하 “회사”라 함)가 운영하는
            “WondanMania” 관련 제반 서비스의 이용과 관련하여 회사와 이용기관 및
            개인과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을
            목적으로 합니다.
          </p>
          <p><strong>제 2조 (용어의 정의)</strong></p>
          <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
          <ol>
            <li>
              “서비스”라 함은 ”회사”가 제공하는 “WondanMania” 웹, 어플리케이션을
              통하여 해당 웹, 어플리케이션이 가진 기능을 사용할 수 있도록 하는
              제반 서비스를 의미합니다.
            </li>
            <li>
              “고객”이라 함은 제3조 제1항에 따라 회원가입을 하여 “회사”가
              제공하는 “서비스”를 받는 사업자와 그 구성원의 사용자 또는 개인
              사용자를 의미합니다.
            </li>
          </ol>
          <p><strong>제 3조 (약관의 효력과 개정)</strong></p>
          <ol>
            <li>
              본 약관은 “서비스”를 이용하기 위해 “고객”이 웹사이트 또는 모바일
              앱에 접속하여 약관내용에 동의를 한 다음 회원가입신청을 하여 회사가
              이를 승인함으로써 효력을 발휘합니다.
            </li>
            <li>
              “회사”는 본 약관의 내용을 “고객”이 쉽게 알 수 있도록 서비스
              초기화면에 게시합니다.
            </li>
            <li>
              “회사”는 새로운 “서비스”의 적용, 보안체계의 향상 및 유지, 정부 등
              공공기관에 의한 시정명령의 이행, “회사”가 제공하는 새로운
              “서비스”의 적용, 기타 “회사”의 업무상 중대한 사유에 의하여 약관을
              변경하여야 할 필요가 있다고 판단 될 경우 관계법령을 위반 하지 않는
              범위 내에서 본 약관을 개정할 수 있습니다.
            </li>
            <li>
              “회사”는 본 약관을 개정하는 경우, 개정 약관의 적용일자 및
              개정사유를 명시하여 현행약관과 함께 “서비스”의 초기화면 에
              게시하는 방법으로 “고객”이 사전에 인지할 수 있도록 약관적용 7일
              전부터 적용 전일까지 공지합니다.
            </li>
            <li>
              “고객”이 개정 약관의 적용에 동의하지 않는 경우, 개정 약관 적용
              전일까지 “서비스” 회원에서 탈퇴할 수 있습니다. “고객”이 그러한
              조치를 취하지 않은 경우 개정 약관의 적용에 동의한 것으로 봅니다.
            </li>
            <li>
              “고객”이 책임질 수 없는 사정(개정 약관에 관한 공지 기간 동안
              “서비스”에 접속하지 않았다는 사정은 ‘책임질 수 없는 사정’에
              해당하지 않는 것으로 합니다.)으로 전항의 회원 탈퇴 조치를 취하지
              못한 경우, 그러한 사정을 입증하여 ‘책임질 수 없는 사정’이 소멸한
              즉시 회원 탈퇴 조치를 취할 수 있습니다.
            </li>
          </ol>
          <p><strong>제 4조 (개인정보의 보호)</strong></p>
          <p>
            “회사”는 관계법령이 정하는 바에 따라 “고객”의 개인정보를 보호하기
            위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련법령 및
            “회사”의 개인정보처리방침이 적용됩니다.
          </p>
          <p><strong>제 5조 (고객정보의 변경)</strong></p>
          <ol>
            <li>
              “고객”은 개인정보관리화면을 통해서 언제든지 본인의 개인정보를
              열람하고 수정할 수 있습니다.
            </li>
            <li>
              “고객”은 회원가입 신청 시 기재한 정보가 변경되었을 경우 온라인으로
              수정하여 그 변경사항을 “회사”에 알려야 합니다.
            </li>
            <li>
              “고객”이 전 항의 내용을 이행하지 않아 발생한 불이익에 대하여
              “회사”는 어떠한 책임도 부담하지 않습니다.
            </li>
          </ol>
          <p><strong>제 6조 (고객의 아이디 및 비밀번호 관리)</strong></p>
          <ol>
            <li>
              “고객”의 아이디와 비밀번호(이하 “식별수단”이하 함)에 관한
              관리책임은 “고객”에게 있으며, 이를 제3자가 이용하도록 하여서는
              안됩니다.
            </li>
            <li>
              “고객”은 “식별수단”이 도용되어 제3자가 사용하고 있음을 인지한
              경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야
              합니다.
            </li>
            <li>
              전 항의 규정에도 불구하고 해당 “고객”이 “회사”에 그 “식별수단”의
              도용 사실을 통지하지 않거나, 통지한 후 “회사”의 안내에 따르지 않아
              “고객”에게 발생한 불이익에 대하여 “회사”는 어떠한 책임도 부담하지
              않습니다.
            </li>
          </ol>
          <p><strong>제 7조 (“서비스” 내용)</strong></p>
          <ol>
            <li>
              “회사”가 “고객”에게 제공하는 “서비스”는 다음과 같습니다 가.
              웹사이트 : “고객”이 이용하는 “웹”에 대한 실행, 관리 및 “고객”이
              속한 사업장 또는 개인 프로필을 관리할 수 있는 온라인 상의 별도
              페이지 나. “앱” : “회사”의 정책에 따라 기본제공 또는 “고객”의
              선택에 따라 “회사”를 통해 무료 및 유료 결제의 방식으로 구매하여
              이용하는 “서비스”
            </li>
          </ol>
          <p><strong>제 8조 (“서비스” 이용)</strong></p>
          <p>
            “고객”은 “회사”에서 정한 별도의 인증 방법을 통해 회원가입을 한 후
            서비스를 무료 혹은 유료로 즉시 이용할 수 있습니다.
          </p>
          <p><strong>제 9조 (데이터 저장)</strong></p>
          <ol>
            <li>
              “서비스” 이용 시 클라우드 인프라를 기반으로 데이터를 저장합니다.
            </li>
            <li>
              “고객”이 유료 및 무료 “서비스” 이용 시 정책에 따라 사용에 따른
              데이터를 저장합니다.
            </li>
          </ol>
          <p><strong>제 10조 (데이터 보호 및 관리)</strong></p>
          <ol>
            <li>
              데이터란 “고객”이 “앱”을 이용하여 입력 또는 생성 후 물리적인
              데이터 서버에 저장된 자료입니다.
            </li>
            <li>
              “회사”는 “고객”의 데이터를 보호하며 고객 데이터에 대한 허락 받지
              않은 접근을 방지하기 위해 최소 업계 표준 시스템 및 절차를
              구현합니다.
            </li>
            <li>
              “회사”에 저장된 데이터는 수사기관의 영장, 법원의 판결 또는 결정 등
              정당한 법률적 절차가 개시된 경우 별도의 “고객” 동의가 없는
              경우라도 요청 기관에 “고객” 및 “고객”이 소속된 이용기관의 데이터를
              제공하거나 공개할 수 있습니다.
            </li>
            <li>
              “회사”는 “회사”의 책임이 없는 사유로 인한 데이터 유실에 대해
              책임지지 않습니다. 가. “고객”의 PC에서 다루는 제3자 제공
              프로그램의 종료 및 오류로 인하여, 파일이 손상되었거나 서버에
              업로드된 파일이 잘못 저장된 경우 나. “고객”의 PC 혹은 스마트폰
              등에서 업로드 시 사용한 네트워크 연결상태 및 네트워크 환경 상의
              장애 및 오류 등으로 인하여 정상적으로 파일이 업로드 되지 않은 경우
              다. “고객”의 PC/네트워크 등 환경 문제, 아이디/비밀번호 분실 및
              도용, 관리소홀 등 사용자 책임이 있는 경우 라. 천재지변,
              국가비상사태 또는 이에 준하는 불가항력적인 사유로 인한 서버파일
              손상이 있는 경우 마. 기타 위 사례를 포함하여, “회사”의 책임이 없는
              사유로 판단될 수 있는 경우
            </li>
            <li>
              유실된 데이터는 복구가 불가능하니 데이터는 “고객”이 별도로
              보관하여야 합니다.
            </li>
            <li>
              저장된 데이터가 서버의 업그레이드, 시스템 점검, 데이터
              마이그레이션 등 기타 통상적인 서비스의 유지 운영의 행위 도중,
              “회사”의 귀책 사유로 데이터의 유실 또는 손상이 발생하였고 그로
              인하여 “고객”이 손해를 입은 경우, “회사”는 “고객”이 입증한 손해를
              배상하되 손해배상액은 최근 12개월 동안 “고객”이 “회사”에 지불한
              서비스 이용요금을 한도로 합니다.
            </li>
          </ol>
          <p><strong>제 11조 (“서비스” 알림 제공)</strong></p>
          <p>
            “회사”는 “고객”의 원활한 “서비스” 제공을 위해 이메일 등의 서비스를
            통해서 알림을 제공합니다.
          </p>
          <p><strong>제 12조 (“서비스” 탈퇴 또는 중지)</strong></p>
          <ol>
            <li>
              “고객”이 “서비스”를 해지하고자 하는 때에는 “고객” 본인이
              “서비스”의 탈퇴기능을 통하여 탈퇴할 수 있습니다.
            </li>
            <li>
              서비스의 경우 “고객”이 “서비스”를 해지하고자 하는 때에는 “회사”
              측에 문의절차를 통해서 알리고 “회사”가 해지과정을 진행합니다.
            </li>
            <li>
              탈퇴 신청의 처리 여부는 “고객”이 “식별수단”을 사용하여 로그인을
              시도하여 로그인이 되지 않음을 확인함으로써 알 수 있습니다.
            </li>
            <li>
              “고객”이 “서비스”를 해지할 경우, 관련 법령 및 개인정보처리방침에
              따라 “회사”가 회원정보를 보유하는 경우를 제외하고는 해지 시
              “고객”의 모든 데이터는 소멸됩니다. 다만 “서비스”에 “고객”이 등록한
              연락처 정보 등은 “사업자” 단위의 관리 서비스로서 “고객”이
              “서비스”를 해지하여도 “고객”이 소속된 “사업자”의 정보로 관리되어
              소멸되지 않습니다.
            </li>
            <li>
              “회사”는 “고객”이 본 약관에서 정한 의무를 위반할 경우 서면,
              이메일, 전화, 기타 방법을 통하여 그 시정 또는 개선을 요구할 수
              있고, 귀책 당사자의 시정 또는 개선 조치가 없을 경우 서면, 이메일,
              전화, 기타 방법을 통하여 통지한 후 “서비스”의 제공을 중지할 수
              있습니다.
            </li>
            <li>
              “회사”는 “고객”에게 다음 어느 하나에 해당하는 상황이 발생할 경우
              사전 통보 없이 “서비스”의 제공을 중지할 수 있습니다. 가.
              금융기관의 지급거절, 해산, 회사정리, 파산 등의 절차가 개시되거나
              이와 유사한 상황이 발생한 경우 나. 주요 자산 또는 영업을 제3자에게
              양도한 경우 다. 중대한 법령 위반 또는 사회적 물의로 인하여
              “서비스” 운영에 막대한 지장을 초래하였거나 초래할 것이 합리적으로
              예상되는 경우 라. 가압류, 가처분, 압류, 공매 경매 개시 등의 절차에
              들어갔거나 들어갈 염려가 있다고 인정될 때 마. 타인의 명의를
              도용하거나 허위 사실로 신청이 확인된 경우 바. 기타 정상적인
              “서비스”운영에 방해가 된다고 “회사”가 판단한 경우
            </li>
          </ol>
          <p><strong>제 13조 (“서비스” 제공 및 변경)</strong></p>
          <ol>
            <li>“서비스”는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
            <li>
              “회사”는 컴퓨터 등 정보통신설비의 보수점검, 노후 및 고장에 의한
              교체, 통신망의 두절 또는 기타 운영상 상당한 이유가 있는 경우
              “서비스”의 제공을 일시적으로 중단할 수 있습니다. 단, “회사”는
              “고객”에게 사전 통지 또는 공지함을 원칙으로 하며, 부득이한
              경우에는 사후 통지할 수 있습니다.
            </li>
            <li>
              “회사”는 “서비스”의 일부 또는 전부를 “회사”의 정책 및 운영상,
              기술상의 필요에 따라 변경할 수 있습니다. 단, “서비스”의 내용,
              이용방법, 이용시간 등 “서비스”의 일부 또는 전부의 변경이 있는
              경우에는 변경사유, 변경될 “서비스”의 내용 및 제공일자를 7일 전
              “서비스”의 제공화면에 게시하여야 합니다.
            </li>
          </ol>
          <p><strong>제 14조 (“서비스” 요금 및 결제)</strong></p>
          <ol>
            <li>
              “회사”는 “서비스”에서 “고객”이 비스를 구매하여 이용할 때 각 버전의
              가격 정책에 따라 월 혹은 연간 단위 등으로 각 유료 서비스 요금을
              합산하여 청구할 수 있습니다.
            </li>
            <li>
              “고객”은 “서비스”에서 이용 중인 유료 서비스의 가격정책에 따라
              정해진 일자에 요금을 결제해야 합니다.
            </li>
            <li>
              “고객”은 미납액이 발생하는 경우 납부의 의무가 있으며 미납이 30일
              이상 지속될 경우 서비스에 제한이 있을 수 있습니다. 미납액에 대한
              포기 및 취소는 가능하지 않으며, 이용료 지불에 대한 납세의 책임
              또한 “고객”에게 있습니다.
            </li>
            <li>
              “서비스”에서 제공하는 청구 및 결제 방식은 “회사”의 정책을
              따릅니다.
            </li>
          </ol>
          <p><strong>제 15조 (회사의 의무)</strong></p>
          <ol>
            <li>
              “회사”는 특별한 사정이 없는 한 “회사”가 제공하는 “서비스”를
              계속적이고 안정적으로 제공하기 위하여 최선을 다하여 노력합니다.
            </li>
            <li>
              “회사”는 “서비스”의 제공설비를 항상 운용 가능한 상태로
              유지보수하며, 설비에 장애가 발생하거나 멸실된 경우 이를 수리
              복구할 수 있도록 최선을 다하여 노력합니다.
            </li>
            <li>
              “회사”는 “고객”으로부터 제기되는 의견이나 불만이 정당한 것으로
              인정될 경우 신속히 처리하여야 합니다. 다만, 신속한 처리가 곤란한
              경우 “고객”에게 그 사유와 처리 일정을 이메일, 서면 또는 전화 등의
              방법으로 통지합니다.
            </li>
          </ol>
          <p><strong>제 16조 (고객의 의무)</strong></p>
          <ol>
            <li>
              “고객”은 본 약관 및 관계 법령을 준수하여야 하며, 기타 회사의 업무
              수행에 지장을 초래하는 행위를 하여서는 안됩니다.
            </li>
            <li>
              “고객”은 본 약관 이외의 이용하는 “서비스”와 관련하여 “회사”가
              통지하는 사항을 준수하여야 합니다.
            </li>
            <li>
              “고객”은 다음 각 호에 해당하는 행위를 하여서는 안됩니다. 가. 신청
              및 등록 또는 변경 시 허위 내용의 등록 나. 타인의 정보 도용 다.
              “회사” 또는 제3자의 지식재산권에 대한 침해 라. “회사” 또는 제3자의
              명예 훼손 및 업무방해 마. 음란한 부호, 문자, 음향, 화상, 영상,
              기타 공서양속에 반하는 정보의 공개 또는 게시 바. “회사”의 동의
              없는 영리 목적의 “서비스” 이용 사. 기타 불법하고 부당한 행위
            </li>
          </ol>
          <p><strong>제 17조 (손해배상 등)</strong></p>
          <ol>
            <li>
              “회사” 또는 “고객”은 어느 일방이 본 약관에서 정한 의무를 위반함에
              따라 상대방에게 손해가 발생한 경우 귀책당사자에게 손해배상을
              청구할 수 있습니다. (회사의 책임을 회사의 고의 또는 중대한 과실에
              기인한 것이 아닌 한 일정 한도로 제한할 필요가 있습니다.)
            </li>
            <li>
              본 약관에서 정한 의무 위반 등으로 인하여 제3자와의 사이에 분쟁
              발생 시 일방 당사자는 자신의 책임과 비용으로 상대방을 면책시키고,
              그로 인한 상대방의 모든 손해를 배상하여야 합니다.
            </li>
          </ol>
          <p><strong>제 18조 (면책조항)</strong></p>
          <ol>
            <li>
              “회사”는 전시, 사변 등 국가비상사태, 천재지변, 기간통신사업자의
              서비스제공 중단, 한전으로부터의 전력공급 중단, 해커의 침입, 컴퓨터
              바이러스, 기타 이와 유사한 사정으로 인한 서비스 시스템의 작동불능
              등 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우 “회사”는
              제공하는 “서비스”의 이용과 관련하여 어떠한 책임도 부담하지
              않습니다.
            </li>
            <li>
              “회사”는 “고객”의 귀책사유로 인하여 발생하는 손해에 대하여
              “회사”는 어떠한 책임도 부담하지 않습니다.
            </li>
            <li>
              “회사”는 “고객” 상호간, “고객”과 제휴사 또는 제3자 상호간에
              “서비스”를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로
              인한 손해를 배상할 책임이 없습니다.
            </li>
          </ol>
          <p><strong>제 19조 (지식재산권 등)</strong></p>
          <ol>
            <li>
              본 약관을 통해 “회사”는 “고객”에게 “서비스”에 대한 사용권만을
              부여하며, “회사”가 작성하여 제공하는 “서비스”에 관한 소유권 및
              지식재산권은 “회사”에 귀속됩니다. 단, “서비스” 중 “회사”와 제휴를
              통해 제휴사가 제공하는 “서비스”에 대한 소유권 및 지식재산권은
              제휴사에 귀속됩니다.
            </li>
            <li>
              “고객”은 “회사”가 제공하는 “서비스”를 “회사”의 사전 동의 없이 영리
              목적으로 복제, 전송, 출판, 배포, 방송, 기타 방법에 의하여
              이용하거나 제3자에게 이용하게 하여서는 안됩니다.
            </li>
            <li>
              “고객”이 “서비스”에 게재한 게시물, 자료에 관한 권리와 책임은
              게시한 “고객”에게 있습니다. “회사”는 “고객”이 게재한 게시물,
              자료에 대하여 “서비스” 내의 게재권을 가지며, 게재한 “고객”의 동의
              없이 이를 영리적인 목적으로 사용하지 않습니다.
            </li>
            <li>
              “회사”는 제16조의 의무를 위반하는 내용을 담고 있는 게시물에 대하여
              수정 또는 삭제할 권한을 갖습니다.
            </li>
          </ol>
          <p><strong>제 20조 (분쟁해결 및 관할법원)</strong></p>
          <ol>
            <li>
              “회사”와 “고객”은 “서비스”와 관련하여 발생한 분쟁을 원만하게
              해결하기 위하여 필요한 모든 노력을 하여야 합니다.
            </li>
            <li>
              “서비스” 이용에 관해 발생한 분쟁이 원만하게 해결되지 아니한 경우
              관련 소송의 관할은 “회사” 본점 소재지를 관할하는 법원으로 합니다.
            </li>
          </ol>
          <p><strong>제 21조 (기타)</strong></p>
          <p>
            본 약관에서 정하지 않은 사항 및 내용 해석상의 이견이 있을 경우에는
            일반 상관습에 따르기로 합니다.
          </p>
          <p>본 약관은 2024년 02월 27일부터 시행됩니다.</p>
        </div>
      </div>
      <div class="d-flex justify-end pt-2">
        <div class="pr-2 d-flex align-center">
          <input
            class="v-checkbox"
            type="checkbox"
            :checked="serviceAgree"
            @click="serviceAgree = !serviceAgree"
          />
        </div>
        <span class="agreeText"> 이용약관에 동의합니다. </span>
      </div>
      <div class="d-flex termsTitle">
        <span> 개인정보 처리방침 </span>
      </div>
      <div class="d-flex justify-center">
        <div class="terms">
          <p>
            본 개인정보 처리방침은 [SoftMani.CO.,LTD/WondaMania]이 제공하는
            서비스 및 웹사이트 등을 이용하는 고객 및 회원들의 개인정보를
            보호하고 이와 관련한 권리를 보장하기 위하여 제공됩니다.
          </p>
          <ol>
            <li>수집하는 개인정보의 항목 및 수집방법</li>
          </ol>
          <ul>
            <li>
              수집항목: [SoftMani.CO.,LTD/WondaMania]은(는) 이용자의 서비스 이용
              과정에서 필요한 최소한의 개인정보를 수집합니다. 수집될 수 있는
              개인정보의 항목은 다음과 같습니다.
              <ul>
                <li>
                  이용자의 식별 정보: 이름, 아이디, 전화번호, 이메일 주소 등
                </li>
                <li>이용자의 기기 정보: IP 주소, 쿠키, 서비스 이용 기록 등</li>
              </ul>
            </li>
            <li>
              수집방법: [SoftMani.CO.,LTD/WondaMania]은(는) 다음과 같은 방법으로
              개인정보를 수집합니다.
              <ul>
                <li>회원가입 및 서비스 이용 과정에서 이용자로부터 직접 수집</li>
                <li>이용자의 기기 정보를 자동으로 수집하는 경우(쿠키 등)</li>
              </ul>
            </li>
          </ul>
          <ol>
            <li>개인정보의 이용목적</li>
          </ol>
          <p>
            [SoftMani.CO.,LTD/WondaMania]은(는) 다음과 같은 목적으로 개인정보를
            이용합니다.
          </p>
          <ul>
            <li>서비스 제공 및 운영</li>
            <li>회원 관리 및 서비스 제공에 필요한 연락</li>
            <li>이용자들에게 새로운 정보나 서비스에 대한 안내</li>
          </ul>
          <ol>
            <li>개인정보의 보유 및 이용기간</li>
          </ol>
          <p>
            [SoftMani.CO.,LTD/WondaMania]은(는) 이용자가 서비스를 이용하는
            동안에 한하여 개인정보를 보유 및 이용합니다. 회원 탈퇴 또는 서비스
            이용 중지 요청 시에는 30일간 보관 되며 해당일 이후 해당 정보를
            파기합니다.
          </p>
          <ol>
            <li>개인정보의 제공 및 공유</li>
          </ol>
          <p>
            [SoftMani.CO.,LTD/WondaMania]은(는) 이용자의 개인정보를 본래의 수집
            목적 이외에 다른 목적으로 이용하거나 제3자에게 제공하지 않습니다.
            단, 다음의 경우에는 예외로 합니다.
          </p>
          <ul>
            <li>이용자들이 사전에 동의한 경우</li>
            <li>법령의 규정에 의거하거나 수사, 조사를 위해 필요한 경우</li>
          </ul>
          <ol>
            <li>개인정보의 안전성 확보 조치</li>
          </ol>
          <p>
            [SoftMani.CO.,LTD/WondaMania]은(는) 이용자의 개인정보를 보호하기
            위해 다음과 같은 기술적, 관리적, 물리적 보안 조치를 취하고 있습니다.
          </p>
          <ul>
            <li>개인정보 처리 시스템의 암호화</li>
            <li>개인정보 접근 제한 및 모니터링 시스템 운영</li>
            <li>해킹, 바이러스 등에 대비한 보안 프로그램 설치 및 운영</li>
          </ul>
          <p>개인정보 보호책임자</p>
          <p>
            [SoftMani.CO.,LTD/WondaMania]은(는) 이용자들의 개인정보 보호와
            관련한 불만 및 문의사항을 처리하는 책임자를 지정하고 있습니다.
            개인정보 보호책임자는 다음과 같습니다.
          </p>
          <ul>
            <li>성명: 김지균</li>
            <li>부서: 기능개발팀</li>
            <li>이메일: kjkim@softmani.com</li>
            <li>전화번호: 02-3011-8556</li>
          </ul>
          <p>
            이용자들은 [SoftMani.CO.,LTD/WondaMania]의 서비스를 이용하면서
            발생하는 개인정보 보호 관련 문의사항을 위의 연락처로 문의하실 수
            있습니다.
          </p>
          <ol>
            <li>개인정보 처리방침 변경</li>
          </ol>
          <p>
            [SoftMani.CO.,LTD/WondaMania]은(는) 개인정보 처리방침을 개정하는
            경우 웹사이트 공지사항 등을 통해 공지할 것입니다. 변경된 개인정보
            처리방침은 공지된 날로부터 시행됩니다.
          </p>
          <p>본 개인정보 처리방침은 2024년 02월 27일부터 시행됩니다.</p>
          <p>[SoftMani.CO.,LTD/WondaMania] 드림</p>
        </div>
      </div>
      <div class="d-flex justify-end pt-2">
        <div class="pr-2 d-flex align-center">
          <input
            class="v-checkbox"
            type="checkbox"
            :checked="privacyAgree"
            @click="privacyAgree = !privacyAgree"
          />
        </div>
        <span class="agreeText"> 이용약관에 동의합니다. </span>
      </div>
      <div>
        <v-btn
          depressed
          color="primary"
          @click="agree"
          :disabled="isAgree"
          width="20%"
        >
          약관 동의
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serviceAgree: false,
      privacyAgree: false,
    };
  },
  computed: {
    isAgree() {
      return !this.serviceAgree || !this.privacyAgree ? true : false;
    },
  },
  methods: {
    agree() {
      this.$store.commit("setSignType", 3);
    },
  },
};
</script>

<style>
.signupTermsForm {
  margin-top: 60px;
  display: inline-flex;
  justify-content: center;
  width: 80%;
  border: thin solid rgba(0, 0, 0, 0.8);
  padding-bottom: 20px;
}
.terms {
  border: thin solid #0000001f;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
  padding-left: 10px;
  font-size: 0.95rem;
}
.termsTitle {
  font-size: 1.1rem;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
}
.termsForm {
  padding-left: 20px;
  padding-right: 20px;
}
.agreeText {
  margin-top: 2px;
}
</style>
