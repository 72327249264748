import { idCheck } from "@/api/auth";
import { validateEmail } from "@/utils/validation";
import emailFindPage from "./phoneFind.vue";
import pwChangeForm from "@/components/organisms/PwFind/pwChangeForm";
export default {
  data() {
    return {
      uuid: "",
      // isUuid: false, //메일 존재 유무 확인,
      isMail: false, // 메일 인증 완료 여부 확인
      erTifiedTime: "",
      cerTifiedNum: "", // 이메일로 전달 될 랜덤 숫자값
      mailCode: "", //입력받은 인증코드
      uuid_hint: "사용자 아이디를 입력하세요. (이메일 형식)",
      isMobile: false,
      activeTab: "email",
      isPw: false,
      isPwConfirm: false,
      passwordConfirm: "",
      authCodeTimer: null, // 타이머
    };
  },
  components: {
    emailFindPage,
    pwChangeForm,
  },
  computed: {
    isUuidBtn() {
      return !validateEmail(this.uuid);
    },
  },
  methods: {
    // 회원가입 된 계정인지 체크
    async memberCheck() {
      try {
        const result = await idCheck(this.uuid);

        if (result.status !== 200) throw `error: ${result.status}`;

        return !result.data;
      } catch (e) {
        console.log(e);
      }
    },
    // 계정 입력 창으로 초기화
    initUuid() {
      this.intervalTime = "";
      this.mailCode = "";
      this.intervalTime = "";
      this.isMail = false;
      this.uuid = "";
    },
    // 임시 비밀번호 발송
    newPassword() {
      const temporaryPw = Math.random().toString(36).slice(2);

      this.$store.dispatch("MODIFY_PW", {
        uuid: this.uuid,
        password: temporaryPw,
      });

      this.$store.dispatch("EMAIL_SEND", {
        reciver: this.uuid, //이메일 주소
        title: "wondanMania 임시 비밀번호 발급", // 이메일 제목
        content: `임시 비밀번호 발급: ${temporaryPw}`, // 내용
      });

      alert("메일로 전달된 임시비밀번호로 로그인 해주세요");
      this.uuid = "";
      this.$router.push("/login");
    },

    // 임의 의 숫자값을 전달 받은 메일로 전달 하는 함수
    async mailCertified() {
      this.intervalTime = "";
      this.mailCode = "";
      this.intervalTime = "";
      this.isMail = false;

      if (await this.memberCheck()) {
        this.cerTifiedNum = Math.round(Math.random() * 1000000);
        this.intervalTime = 2 * 60 * 1000;

        this.$store.dispatch("EMAIL_SEND", {
          reciver: this.uuid, //이메일 주소
          title: "wondanMania 이메일 인증 코드", // 이메일 제목
          content: `인증 번호 : ${this.cerTifiedNum}`, // 내용
        });

        this.setErTifiedTime();
      } else {
        this.uuid = "";
        this.uuid_hint = "계정 정보가 없습니다. 다시 확인 해주시기 바랍니다.";

        this.initUuid();
      }
    },

    // 보안 코드 남은 시간 계산 하는 함수
    setErTifiedTime() {
      this.authCodeTimer = setInterval(() => {
        if (this.intervalTime) {
          this.intervalTime = this.intervalTime - 1000;
          this.erTifiedTimeCaclu(this.intervalTime);
        } else {
          this.cerTifiedNum = "";
          this.erTifiedTime = "";
          clearInterval(this.authCodeTimer);
        }
      }, 1000);
      if (this.erTifiedTime) clearInterval(this.authCodeTimer);
    },

    // 전달 받은 밀리세컨트 를 분/초 로 변환 하는 기능
    erTifiedTimeCaclu(time) {
      const min = String(Math.floor((time / (1000 * 60)) % 60)).padStart(
        2,
        "0",
      );
      const sec = String(Math.floor((time / 1000) % 60)).padStart(2, "0");
      this.erTifiedTime = `${min}분${sec}초`;
    },

    /**
     * 입력 받은 값과 메인 인증 코드가 같은 값인지 체크 하는 함수
     * 1. 같을 경우 잔여 시간 삭제, 보안 코드 삭제
     * 2. 다를 경우 입력 받은 값을 삭제 하고 보안 코드가 다르다는 안내 출력
     */
    inputCode() {
      if (+this.mailCode === this.cerTifiedNum) {
        this.intervalTime = "";
        this.mailCodeHint = "메일 인증 완료";
        this.isMail = true;
      } else {
        this.mailCode = "";
        this.mailCodeHint = "메일 인증 코드가 맞지않습니다";
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  mounted() {
    this.onResize();
    // window.addEventListener("resize", this.onResize, { passive: true });
  },
};
