<template>
  <div>
    <template v-if="!isCodeAuth">
      <v-text-field
        v-model="uuid"
        label="계정 입력"
        prepend-icon="mdi-account"
        type="text"
        :hint="uuid_hint"
        @input="uuid_hint = '사용자 아이디를 입력하세요. (이메일 형식)'"
        :persistent-hint="true"
        :disabled="isMail"
        @keydown.enter.native="mailCertified()"
      >
        <template v-slot:append="">
          <v-btn
            color="primary"
            small
            :disabled="isUuidBtn"
            @click="mailCertified()"
            style="transform: translateY(-5px)"
          >
            아이디 인증
          </v-btn>
        </template>
      </v-text-field>
      <v-text-field
        v-model="tel"
        label="전화번호 입력"
        prepend-icon="mdi-account"
        type="text"
        :hint="tel_hint"
        @input="tel = getPhoneMask($event)"
        :disabled="isCheckPhone || !isMail"
        :persistent-hint="true"
        counter="13"
        maxlength="13"
        @keydown.enter.native="phoneCertified()"
        :class="
          isCheckPhone === true
            ? 'telInput'
            : isCheckPhone === false
            ? 'telInputCaution'
            : ''
        "
      >
        <template v-slot:append="">
          <v-btn
            color="primary"
            small
            :disabled="isCheckPhone || !isMail"
            @click="phoneCertified()"
            style="transform: translateY(-5px)"
          >
            전화번호 인증
          </v-btn>
        </template>
      </v-text-field>
      <v-text-field
        v-model="auth_code"
        label="문자 인증코드 입력"
        prepend-icon="mdi-account"
        type="number"
        :hint="isTimerEnd ? auth_code_hint : erTifiedTime"
        :disabled="!isCallAuth"
        :persistent-hint="true"
      >
        <template v-slot:append="">
          <div class="d-flex">
            <div class="pr-2">
              <v-btn
                color="green"
                small
                :disabled="!isTimerEnd || !isCallAuth"
                @click="authCodeSend()"
                style="transform: translateY(-5px)"
              >
                <span class="white--text"> 재전송 </span>
              </v-btn>
            </div>
            <template v-if="!isCallAuth">
              <v-btn
                color="primary"
                small
                @click="authCodeSend()"
                style="transform: translateY(-5px)"
                :disabled="!isCheckPhone"
              >
                <span> 인증코드 전송 </span>
              </v-btn>
            </template>
            <template v-if="isCallAuth">
              <v-btn
                color="primary"
                small
                @click="authCodeCertified()"
                :disabled="isTimerEnd"
                style="transform: translateY(-5px)"
              >
                <span> 인증코드 확인 </span>
              </v-btn>
            </template>
          </div>
        </template>
      </v-text-field>
    </template>
    <template v-if="isCodeAuth">
      <pwChangeForm />
    </template>
  </div>
</template>

<script>
import { validateEmail } from "@/utils/validation";
import { randomFourDigitNumber } from "@/utils/randomize";
import { idCheck, authCodeSend } from "@/api/auth";
import { checkPhoneNumber } from "@/api/user";
import pwChangeForm from "@/components/organisms/PwFind/pwChangeForm";
export default {
  data() {
    return {
      uuid: "",
      uuid_hint: "사용자 아이디를 입력하세요. (이메일 형식)",
      tel_hint: "사용자 아이디를 먼저 입력해주세요.",
      auth_code_hint: "문자로 전송 된 인증번호를 입력해주세요.",
      auth_code: "",
      sending_code: "",
      tel: "",
      isMail: false, // 메일 인증 완료 여부 확인
      isCheckPhone: "", // 휴대폰번호 인증 여부
      isCallAuth: false, // 문자인증요청 여부
      isCodeAuth: false, // 인증코드 인증완료 여부 확인
      isEqualAuthCode: false, //인증코드 일치여부
      erTifiedTime: "",
      intervalTime: "",
      isTimerEnd: true, //타이머 종료여부
      authCodeTimer: null, // 타이머
      password: "",
      passwordConfirm: "",
      isPw: false,
      isPwConfirm: false,
    };
  },
  components: {
    pwChangeForm,
  },
  computed: {
    isUuidBtn() {
      if (this.isMail) return true;
      return !validateEmail(this.uuid);
    },
  },
  methods: {
    async memberCheck() {
      try {
        const result = await idCheck(this.uuid);

        if (result.status !== 200) throw `error: ${result.status}`;

        return !result.data;
      } catch (e) {
        console.log(e);
      }
    },
    async phoneCheck() {
      try {
        const result = await checkPhoneNumber({
          tel: this.tel,
          uuid: this.uuid,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        return result.data;
      } catch (e) {
        console.log(e);
      }
    },
    async mailCertified() {
      this.intervalTime = 0;
      this.isMail = false;

      if (await this.memberCheck()) {
        this.isMail = true;

        this.uuid_hint = "아이디 인증이 완료되었습니다.";
      } else {
        this.uuid = "";
        this.uuid_hint = "계정 정보가 없습니다. 다시 확인 해주시기 바랍니다.";

        this.initUuid();
      }
    },
    async phoneCertified() {
      const isPhone = await this.phoneCheck(); // 존재하는 휴대폰인지 체크

      if (isPhone) {
        this.isCheckPhone = true;
        this.tel_hint = "전화번호 인증이 완료되었습니다.";
      }
      if (!isPhone) {
        this.isCheckPhone = false;
        this.tel_hint = "등록 된 전화번호와 일치하지않습니다. 다시확인해주세요";
      }
    },
    initUuid() {
      this.isMail = false;
      this.uuid = "";
    },
    initTimer() {
      clearInterval(this.authCodeTimer);
      this.erTifiedTime = "";
      this.intervalTime = "";
      this.auth_code_hint = "문자로 전송 된 인증번호를 입력해주세요.";
      this.isTimerEnd = true;
    },
    async authCodeSend() {
      try {
        this.sending_code = randomFourDigitNumber();
        const result = await authCodeSend({
          code: this.sending_code,
          tel: this.tel,
        });
        this.intervalTime = 3 * 60 * 1000;
        this.setErTifiedTime();
        this.isCallAuth = true;
        if (result.data === 202 || result.data === 200 || result.data === 201)
          alert("인증코드가 발송되었습니다. 확인해주세요.");
        else alert("인증코드 발신에 실패하였습니다. 재시도해주세요.");
      } catch (e) {
        alert("인증코드 발신에 실패하였습니다. 재시도해주세요.");
      }
    },

    setErTifiedTime() {
      this.isTimerEnd = false;
      this.authCodeTimer = setInterval(() => {
        if (this.intervalTime) {
          this.intervalTime = this.intervalTime - 1000;
          this.erTifiedTimeCaclu(this.intervalTime);
        } else {
          this.erTifiedTime = "";

          this.initTimer();
        }
      }, 1000);
      if (this.erTifiedTime) this.initTimer();
    },
    erTifiedTimeCaclu(time) {
      const min = String(Math.floor((time / (1000 * 60)) % 60)).padStart(2);
      const sec = String(Math.floor((time / 1000) % 60)).padStart(2, "0");
      this.erTifiedTime = `${min}분${sec}초`;
    },
    authCodeCertified() {
      const call_AuthCode = this.sending_code;
      if (this.auth_code === call_AuthCode) {
        this.isEqualAuthCode = true;
        this.isCodeAuth = true;
        this.initTimer();
      } else {
        this.isEqualAuthCode = false;
        this.$store.commit("setSnackBar", `인증번호가 일치하지않습니다.`);
      }
    },
  },
};
</script>

<style></style>
